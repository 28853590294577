import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Button, Pagination, Row, Col, Form, Dropdown } from 'react-bootstrap';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';
import classNames from 'classnames/bind';
import DOMPurify from 'dompurify';

import Loading from '../../../components/Loading/Loading';
import throttle from '../../../utils/throttle';
import pagination from '../../../utils/pagination';
import { getNotifications, updateNotification, createNotification, removeNotification } from '../NotificationsActions';
import NotificationConfig from './NotificationConfig';

import settingIcon from '../../../assets/images/settings-gray-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';

import styles from '../../../assets/styles/_table.scss';
import nstyles from './NotificationsTable.scss';
let cx = classNames.bind(styles, nstyles);

class NotificationsTable extends Component {
  static propTypes = {
    pagination: PropTypes.bool,
    show: PropTypes.number,
  };

  static defaultProps = {
    pagination: true,
    show: 10,
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      dateFormat: 'DD.MM.YYYY HH:mm',
      search: '',
    };

    this.getNotificationsThrottled = throttle(this.getNotifications, 500, this);
  }

  componentDidMount() {
    this.getNotifications();
  }

  handleChange = (ev) => {
    this.setState({ search: ev.target.value, page: 1 });
    this.getNotificationsThrottled();
  };

  goto = (page = 1) => {
    if (page < 1) {
      page = 1;
    }

    this.setState({ page }, this.getNotifications);
  };

  getNotifications = () => {
    const { search, page } = this.state;
    this.props.getNotifications({
      search,
      page,
    });
  };

  getAudience(id) {
    switch (id) {
      case '1':
        return 'Has billing access';
      case '2':
        return 'Has monthly plan';
      case '3':
        return 'Has annual plan';
      case '4':
        return 'Trials';
      case '5':
        return 'Enrolled in SP';
      default:
        return 'All users';
    }
  }

  getAvailability(from, to, enabled) {
    const date = +new Date();
    if (!enabled) return ['red', 'OFFLINE'];
    if (date < from || date > to) return ['red', 'OFFLINE'];
    return ['green', 'ONLINE'];
  }

  toggleNotification(_id) {
    const notification = _.find(this.props.results, { _id });
    if (!notification) return;
    const model = {
      ...notification,
      enabled: !notification.enabled,
    };
    this.props.updateNotification(model);
  }

  renderPagination = () => {
    const pages = pagination(this.props.total, this.props.page, 10, 5);

    if (pages.totalPages < 2) {
      return <div></div>;
    }
    return (
      <Pagination>
        {pages.currentPage > 1 && <Pagination.First onClick={() => this.goto(1)} />}
        {pages.currentPage > 1 && <Pagination.Prev onClick={() => this.goto(pages.currentPage - 1)} />}
        {pages.pages.map((p, i) => {
          return (
            <Pagination.Item key={i} active={p === pages.currentPage} onClick={() => this.goto(p)}>
              {p}
            </Pagination.Item>
          );
        })}
        {pages.currentPage < pages.totalPages && <Pagination.Next onClick={() => this.goto(pages.currentPage + 1)} />}
        {pages.currentPage < pages.totalPages && <Pagination.Last onClick={() => this.goto(pages.totalPages)} />}
      </Pagination>
    );
  };

  renderRow = (data, i) => {
    const { dateFormat } = this.state;

    const [timeRangeClass, timeRangeLabel] = this.getAvailability(data.from, data.to, data.enabled);

    const formatted = data.description
      .replace(/rel="noopener noreferrer"/gm, 'rel="noopener noreferrer" target="_blank"')
      .replace(/<p>/gm, '<span>')
      .replace(/<\/p>/gm, '</span>');

    const sanitized = DOMPurify.sanitize(formatted, { FORCE_BODY: true, ADD_ATTR: ['target'] });

    return (
      <Row key={i} className={cx('row-item')}>
        {/* Col 1 */}
        <Col xs={12} md={4} className={cx('col-one')}>
          <p>
            <label>Name:</label>
          </p>
          <p>
            <span>{data.name}</span>
          </p>
          <p>
            <label>Description:</label>
          </p>
          <p className={cx('description-content')} dangerouslySetInnerHTML={{ __html: sanitized }}></p>
        </Col>

        {/* Col 2 */}
        <Col className={cx('col-two')}>
          <p>
            <label>Starts on:</label>
          </p>
          <p>
            <span>{moment(data.from).format(dateFormat)}</span>
          </p>
          <p>
            <label>Ends on:</label>
          </p>
          <p>
            <span>{data.to && moment(data.to).format(dateFormat)}</span>
          </p>
        </Col>

        {/* Col 3 */}
        <Col className={cx('col-three')}>
          <p>
            <label>Status:</label>
          </p>
          <p>
            <span className={cx(`status ${timeRangeClass}`)}>{timeRangeLabel}</span>
          </p>
        </Col>

        {/* Col 4 */}
        <Col className={cx('col-four')}>
          <p>
            <label>Audience:</label>
          </p>
          <p>
            {_.map(data.audience, (value, key) => (
              <span className={cx(`audience ${value === true ? 'include' : 'exclude'}`)} key={key}>
                {this.getAudience(key)}
              </span>
            ))}
          </p>
        </Col>

        {/* Col 5 */}
        <Col className={cx('col-five')}>
          <Dropdown className="dropdown-settings-menu accounts">
            <Dropdown.Toggle className="btn-menu">
              <img src={settingIcon} alt="#" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {data.enabled && (
                <Dropdown.Item>
                  <Confirm
                    body={
                      <p>
                        Are you sure you want to disable <br />
                        this notification?
                      </p>
                    }
                    confirmText="Disable"
                    icon={settingIcon}
                    title="Disable notification"
                    onConfirm={() => this.toggleNotification(data._id)}
                  >
                    <Button className={cx('btn_dropdown')}>Disable</Button>
                  </Confirm>
                </Dropdown.Item>
              )}
              <NotificationConfig model={data} onClose={this.getNotifications}>
                <li className={cx('dropdown-item')}>Edit</li>
              </NotificationConfig>
              <Dropdown.Item>
                <Confirm
                  body={
                    <p>
                      Are you sure you want to remove <br />
                      this notification?
                    </p>
                  }
                  confirmText="Remove"
                  icon={deleteIcon}
                  title="Remove notification"
                  onConfirm={() => this.props.removeNotification(data._id)}
                >
                  <Button className={cx('btn_dropdown')}>Remove</Button>
                </Confirm>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    );
  };

  onClose() {}

  handleInput() {}

  handleSubmit() {}

  render() {
    const { isLoading, results, total } = this.props;

    return (
      <div>
        <div className={cx('table-content')}>
          <div className={cx('table-filter')}>
            <div className={cx('filter-content')} id="filter-content">
              <NotificationConfig onClose={this.getNotifications}>
                <Button variant="white-shadow" style={{ marginTop: '12px', width: '120px', opacity: '1' }}>
                  Create new
                </Button>
              </NotificationConfig>
            </div>
            <Form.Group controlId="Search" className="u-di search-form white">
              <Form.Control
                type="text"
                name="search"
                placeholder="Search"
                onChange={this.handleChange}
                value={this.state.search}
              />
            </Form.Group>
          </div>

          {isLoading && (
            <div className={cx('table-loading')}>
              <Loading />
            </div>
          )}
          {!isLoading && total === 0 && (
            <div className={cx('no_data')}>
              <p> No data available </p>
            </div>
          )}

          <div className={cx('table-list')}>
            {!isLoading && results && _.map(results, (data, i) => this.renderRow(data, i))}
          </div>
        </div>
        {total > 0 && this.renderPagination()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (opt) => dispatch(getNotifications(opt)),
    createNotification: (model) => dispatch(createNotification(model)),
    removeNotification: (id) => dispatch(removeNotification(id)),
    updateNotification: (model) => dispatch(updateNotification(model)),
  };
};

function mapStateToProps(state) {
  return {
    results: state.notifications.results,
    isLoading: state.notifications.isLoading,
    page: state.notifications.page,
    total: state.notifications.total,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);
